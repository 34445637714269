/* eslint-disable no-new */
import React, { useEffect } from 'react'
import { Loader } from 'google-maps'
import { graphql, useStaticQuery } from 'gatsby'
import { Query } from '../../../types/generated'
import { getWindow } from '../../../utils'
import './index.css'

export const ParagraphSchoolMapFields = graphql`
  fragment ParagraphSchoolMapFields on paragraph__school_map {
    __typename
    id
  }
`

const mapContent = (school: any) => `
  <div> 
    <a class="text-xl" href="https://discover.sandiegounified.org${school?.path?.alias}" target="_blank" rel="noopener noreferrer">
      ${school.title}
    </a>
    <div>
      <p>
        ${school?.field_address?.address_line1}, ${school?.field_address?.locality}, ${school?.field_address?.administrative_area} ${school?.field_address?.postal_code}
      </p>
    </div>
  </div>
`

const ParagraphSchoolMap = () => {
  const data = useStaticQuery<Query>(graphql`
    query {
      allNodeSchool(filter: { langcode: { eq: "en" } }) {
        nodes {
          title
          path {
            alias
          }
          field_address {
            address_line1
            locality
            administrative_area
            postal_code
          }
          field_latitude
          field_longitude
        }
      }
    }
  `)

  const schools = data?.allNodeSchool?.nodes

  useEffect(() => {
    const mapElement = document.getElementById('map')

    async function initMap() {
      if (!mapElement) return

      const loader = new Loader('AIzaSyBmQnQ8nY8qjIXv-Fpv_QAuXXn3_0bKrUI', {})
      await loader.load()

      const myLatLng = { lat: 32.8176554, lng: -117.1913356 }

      const map = new google.maps.Map(mapElement, {
        zoom: 11,
        center: myLatLng,
      })

      const infowindow = new google.maps.InfoWindow()

      schools.map((school) => {
        const marker = new google.maps.Marker({
          position: {
            lat: school?.field_latitude ?? 0,
            lng: school?.field_longitude ?? 0,
          },
          map,
          title: school?.title ?? '',
        })

        google.maps.event.addListener(marker, 'click', () => {
          infowindow.setContent(mapContent(school))
          infowindow.open(map, marker)
        })

        return true
      })
    }

    if (getWindow() && mapElement) initMap()
  }, [])

  return (
    <div className="map-container">
      <div id="map">&nbsp;</div>
    </div>
  )
}

export default ParagraphSchoolMap
